<template>
    <!-- 视频播放组件 -->
    <div class="video_play_box" 
        v-if="info.videoSrc" 
        v-html="info.videoSrc">
        <!-- <embed :src='info.videoSrc' allowFullScreen='true' quality='high' width='100%' height='100%' align='middle' allowScriptAccess='always' type='application/x-shockwave-flash'></embed> -->
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: mapState({
        info: state => state.goodsDetail.info,
    }),
	mounted() {
		console.log("看看是什么好东西",this.info)
	}
}
</script>

<style scoped>
/* 视频播放插件盒子 */
.video_play_box{
    height: 500px;
}
.video_play_box>>>iframe{
    width: 100%;
    height: 100%;
}
</style>
